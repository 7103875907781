import React from "react";
import { RedocStandalone } from "redoc";
import spec from "./schema/spec.yaml";
import TopNavbar from "./components/TopNavbar.js";
import Reference from "./components/Reference/Reference.js";
// Material UI.
import {
  ThemeProvider,
  createTheme,
  responsiveFontSizes,
} from "@mui/material/styles";

import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";

function App() {
  let theme = createTheme({
    fontFamily: "Arial, sans-serif",
    palette: {
      primary: {
        main: "#011f4b",
      },
      secondary: {
        main: "#03396c",
      },
      default: {
        main: "#fff",
      },
      danger: {
        main: "red",
      },
    },
    components: {
      MuiLink: {
        styleOverrides: {
          root: {
            textDecoration: "none",
            cursor: "pointer",
          },
        },
      },
    },
  });
  theme = responsiveFontSizes(theme);

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route
            path="*"
            element={
              <>
                <TopNavbar />
                <RedocStandalone
                  specUrl={spec}
                  options={{
                    nativeScrollbars: true,
                    scrollYOffset: ".navbar",
                    theme: { colors: { primary: { main: "#011f4b" } } },
                    hideDownloadButton: true,
                  }}
                />
                <Reference />
              </>
            }
          />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
