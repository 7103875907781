import React, { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import "./Reference.css";
import countryListAlpha2 from "./data/countryCodes.js";
import {
  dhlDomesticServices,
  dhlInternationalServices,
  fedexDomesticServices,
  fedexInternationalServices,
  upsDomesticServices,
  upsInternationalServices,
  sendleDomesticServices,
  sendleInternationalServices,
} from "./data/serviceCodes.js";
import currencyCodes from "./data/currencyCodes.js";
import { fedexPackageTypes } from "./data/packageCodes.js";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Divider,
  Dialog,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const CustomModal = ({ children, setModalContent }) => {
  return (
    <Dialog
      open={true}
      onClose={() => {
        setModalContent("");
      }}
    >
      <Typography align="right">
        <IconButton onClick={() => setModalContent("")}>
          <CloseIcon />
        </IconButton>
      </Typography>
      {children}
    </Dialog>
  );
};

const CountryCodesTable = () => {
  return (
    <TableContainer align="center">
      <Table size="small" component={Paper}>
        <TableHead>
          <TableRow>
            <TableCell align="left">Country</TableCell>
            <TableCell align="left">Code</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.keys(countryListAlpha2).map((code, i) => (
            <TableRow
              key={code}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                "&:nth-of-type(odd)": { backgroundColor: "#fafafa" },
              }}
            >
              <TableCell align="left">{countryListAlpha2[code]}</TableCell>
              <TableCell align="left">{code}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const ServiceCodesTable = () => {
  return (
    <TableContainer align="center">
      <Table size="small" component={Paper}>
        <TableHead>
          <TableRow>
            <TableCell align="center" colSpan="2">
              DHL Service Codes (Domestic)
            </TableCell>
          </TableRow>
        </TableHead>

        <TableHead>
          <TableRow>
            <TableCell align="left">Service Description</TableCell>
            <TableCell align="left">Code</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {Object.keys(dhlDomesticServices).map((code, i) => (
            <TableRow
              key={code}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                "&:nth-of-type(odd)": { backgroundColor: "#fafafa" },
              }}
            >
              <TableCell align="left">{dhlDomesticServices[code]}</TableCell>
              <TableCell align="left">{code}</TableCell>
            </TableRow>
          ))}
        </TableBody>

        <TableHead>
          <TableRow>
            <TableCell align="center" colSpan="2">
              DHL Service Codes (International)
            </TableCell>
          </TableRow>
        </TableHead>

        <TableHead>
          <TableRow>
            <TableCell align="left">Service Description</TableCell>
            <TableCell align="left">Code</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {Object.keys(dhlInternationalServices).map((code, i) => (
            <TableRow
              key={code}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                "&:nth-of-type(odd)": { backgroundColor: "#fafafa" },
              }}
            >
              <TableCell align="left">
                {dhlInternationalServices[code]}
              </TableCell>
              <TableCell align="left">{code}</TableCell>
            </TableRow>
          ))}
        </TableBody>

        <TableHead>
          <TableRow>
            <TableCell align="center" colSpan="2">
              Fedex Service Codes (Domestic)
            </TableCell>
          </TableRow>
        </TableHead>

        <TableHead>
          <TableRow>
            <TableCell align="left">Service Description</TableCell>
            <TableCell align="left">Code</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {fedexDomesticServices.map((code, i) => (
            <TableRow
              key={code}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                "&:nth-of-type(odd)": { backgroundColor: "#fafafa" },
              }}
            >
              <TableCell align="left">{code.replaceAll("_", " ")}</TableCell>
              <TableCell align="left">{code}</TableCell>
            </TableRow>
          ))}
        </TableBody>

        <TableHead>
          <TableRow>
            <TableCell align="center" colSpan="2">
              Fedex Service Codes (International)
            </TableCell>
          </TableRow>
        </TableHead>

        <TableHead>
          <TableRow>
            <TableCell align="left">Service Description</TableCell>
            <TableCell align="left">Code</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {fedexInternationalServices.map((code, i) => (
            <TableRow
              key={code}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                "&:nth-of-type(odd)": { backgroundColor: "#fafafa" },
              }}
            >
              <TableCell align="left">{code.replaceAll("_", " ")}</TableCell>
              <TableCell align="left">{code}</TableCell>
            </TableRow>
          ))}
        </TableBody>

        <TableHead>
          <TableRow>
            <TableCell align="center" colSpan="2">
              UPS Service Codes (Domestic)
            </TableCell>
          </TableRow>
        </TableHead>

        <TableHead>
          <TableRow>
            <TableCell align="left">Service Description</TableCell>
            <TableCell align="left">Code</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {Object.keys(upsDomesticServices).map((code, i) => (
            <TableRow
              key={code}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                "&:nth-of-type(odd)": { backgroundColor: "#fafafa" },
              }}
            >
              <TableCell align="left">{upsDomesticServices[code]}</TableCell>
              <TableCell align="left">{code}</TableCell>
            </TableRow>
          ))}
        </TableBody>

        <TableHead>
          <TableRow>
            <TableCell align="center" colSpan="2">
              UPS Service Codes (International)
            </TableCell>
          </TableRow>
        </TableHead>

        <TableHead>
          <TableRow>
            <TableCell align="left">Service Description</TableCell>
            <TableCell align="left">Code</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {Object.keys(upsInternationalServices).map((code, i) => (
            <TableRow
              key={code}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                "&:nth-of-type(odd)": { backgroundColor: "#fafafa" },
              }}
            >
              <TableCell align="left">
                {upsInternationalServices[code]}
              </TableCell>
              <TableCell align="left">{code}</TableCell>
            </TableRow>
          ))}
        </TableBody>

        <TableHead>
          <TableRow>
            <TableCell align="center" colSpan="2">
              Sendle Service Codes (Domestic)
            </TableCell>
          </TableRow>
        </TableHead>

        <TableHead>
          <TableRow>
            <TableCell align="left">Service Description</TableCell>
            <TableCell align="left">Code</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {Object.keys(sendleDomesticServices).map((code, i) => (
            <TableRow
              key={code}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                "&:nth-of-type(odd)": { backgroundColor: "#fafafa" },
              }}
            >
              <TableCell align="left">{sendleDomesticServices[code]}</TableCell>
              <TableCell align="left">{code}</TableCell>
            </TableRow>
          ))}
        </TableBody>

        <TableHead>
          <TableRow>
            <TableCell align="center" colSpan="2">
              Sendle Service Codes (International)
            </TableCell>
          </TableRow>
        </TableHead>

        <TableHead>
          <TableRow>
            <TableCell align="left">Service Description</TableCell>
            <TableCell align="left">Code</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {Object.keys(sendleInternationalServices).map((code, i) => (
            <TableRow
              key={code}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                "&:nth-of-type(odd)": { backgroundColor: "#fafafa" },
              }}
            >
              <TableCell align="left">
                {sendleInternationalServices[code]}
              </TableCell>
              <TableCell align="left">{code}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const PackageCodesTable = () => {
  return (
    <TableContainer align="center">
      <Table size="small" component={Paper}>
        <TableHead>
          <TableRow>
            <TableCell align="left">Package Description (Fedex)</TableCell>
            <TableCell align="left">Code</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.keys(fedexPackageTypes).map((key, i) => (
            <TableRow
              key={key}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                "&:nth-of-type(odd)": { backgroundColor: "#fafafa" },
              }}
            >
              <TableCell align="left">{fedexPackageTypes[key]}</TableCell>
              <TableCell align="left">{key}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const CurrencyCodesTable = () => {
  return (
    <TableContainer align="center">
      <Table size="small" component={Paper}>
        <TableHead>
          <TableRow>
            <TableCell align="left">Country</TableCell>
            <TableCell align="left">Code</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currencyCodes.map((obj, i) => (
            <TableRow
              key={obj.currency_code}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                "&:nth-of-type(odd)": { backgroundColor: "#fafafa" },
              }}
            >
              <TableCell align="left">{obj.country}</TableCell>
              <TableCell align="left">{obj.currency_code}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const Reference = () => {
  const [dialogName, setDialogName] = useState("");

  useEffect(function () {
    window.triggerRSAPIDocsInfoModal = function (anchor) {
      if (
        [
          "service-codes",
          "package-codes",
          "country-codes",
          "currency-codes",
        ].includes(anchor)
      ) {
        setDialogName(anchor);
      }
      return;
    };
  }, []);

  return (
    <>
      {dialogName === "country-codes" && (
        <CustomModal setModalContent={setDialogName}>
          <Typography variant="h5" align="center">
            Country Codes
          </Typography>
          <Divider />
          <CountryCodesTable />
        </CustomModal>
      )}

      {dialogName === "service-codes" && (
        <CustomModal setModalContent={setDialogName}>
          <Typography variant="h5" align="center">
            Service Codes
          </Typography>
          <Divider />
          <ServiceCodesTable />
        </CustomModal>
      )}

      {dialogName === "package-codes" && (
        <CustomModal setModalContent={setDialogName}>
          <Typography variant="h5" align="center">
            Package Codes
          </Typography>
          <Divider />
          <PackageCodesTable />
        </CustomModal>
      )}

      {dialogName === "currency-codes" && (
        <CustomModal setModalContent={setDialogName}>
          <Typography variant="h5" align="center">
            Currency Codes
          </Typography>
          <Divider />
          <CurrencyCodesTable />
        </CustomModal>
      )}
    </>
  );
};

export default Reference;
