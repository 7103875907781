export const dhlDomesticServices = {
  1: "EXPRESS DOMESTIC 12:00",
  2: "B2C",
  3: "B2C",
  4: "JETLINE",
  5: "SPRINTLINE",
  7: "EXPRESS EASY",
  8: "EXPRESS EASY",
  9: "EUROPACK",
  E: "EXPRESS 9:00",
  G: "DOMESTIC ECONOMY SELECT",
  H: "ECONOMY SELECT",
  I: "EXPRESS DOMESTIC 9:00",
  J: "JUMBO BOX",
  K: "EXPRESS 9:00",
  N: "EXPRESS DOMESTIC",
  O: "EXPRESS DOMESTIC 10:30",
  S: "SAME DAY",
  T: "EXPRESS 12:00",
  V: "EUROPACK",
  W: "ECONOMY SELECT",
  X: "EXPRESS ENVELOPE",
  Y: "EXPRESS 12:00",
};

export const dhlInternationalServices = {
  B: "BREAKBULK EXPRESS",
  C: "MEDICAL EXPRESS",
  D: "EXPRESS WORLDWIDE",
  E: "EXPRESS 9:00",
  F: "FREIGHT WORLDWIDE",
  J: "JUMBO BOX",
  K: "EXPRESS 9:00",
  L: "EXPRESS 10:30",
  M: "EXPRESS 10:30",
  P: "EXPRESS WORLDWIDE",
  Q: "MEDICAL EXPRESS",
  R: "GLOBALMAIL BUSINESS",
  S: "SAME DAY",
  T: "EXPRESS 12:00",
  U: "EXPRESS WORLDWIDE",
  V: "EUROPACK",
  W: "ECONOMY SELECT",
  X: "EXPRESS ENVELOPE",
  Y: "EXPRESS 12:00",
};

export const fedexInternationalServices = [
  "FEDEX_INTERNATIONAL_PRIORITY_EXPRESS",
  "EUROPE_FIRST_INTERNATIONAL_PRIORITY",
  "INTERNATIONAL_ECONOMY_DISTRIBUTION",
  "INTERNATIONAL_FIRST",
  "FEDEX_INTERNATIONAL_PRIORITY",
  "FEDEX_INTERNATIONAL_PRIORITY_PLUS",
  "INTERNATIONAL_PRIORITY_DISTRIBUTION",
  "FEDEX_INTERNATIONAL_CONNECT_PLUS",
  "INTERNATIONAL_ECONOMY",
];

export const fedexDomesticServices = [
  "FEDEX_EXPRESS_SAVER",
  "FEDEX_2_DAY",
  "FIRST_OVERNIGHT",
  "FEDEX_2_DAY_AM",
  "FEDEX_NEXT_DAY_END_OF_DAY",
  "FEDEX_REGIONAL_ECONOMY",
  "TRANSBORDER_DISTRIBUTION_CONSOLIDATION",
  "SMART_POST",
  "SAME_DAY_CITY",
  "FEDEX_PRIORITY_OVERNIGHT_EXTRA_HOURS",
  "SAME_DAY",
  "FEDEX_STANDARD_OVERNIGHT_EXTRA_HOURS",
  "PRIORITY_OVERNIGHT",
  "STANDARD_OVERNIGHT",
  "FEDEX_NEXT_DAY_AFTERNOON",
  "FEDEX_NEXT_DAY_EARLY_MORNING",
  "FEDEX_NEXT_DAY_MID_MORNING",
  "FEDEX_FIRST_OVERNIGHT_EXTRA_HOURS",
  "FEDEX_GROUND",
  "GROUND_HOME_DELIVERY",
];

export const upsDomesticServices = {
  "02": "UPS 2nd Day Air",
  59: "UPS 2nd Day Air A.M.",
  12: "UPS 3 Day Select",
  "03": "UPS Ground",
  "01": "UPS Next Day Air",
  14: "UPS Next Day Air Early",
  13: "UPS Next Day Air Saver",
};

export const upsInternationalServices = {
  11: "UPS Standard",
  "07": "UPS Worldwide Express",
  "08": "UPS Worldwide Expedited",
  54: "UPS Worldwide Express Plus",
  65: "UPS Worldwide Saver",
};

export const sendleDomesticServices = {
  "SAVER-DROPOFF": "Sendle Saver - Drop off, economy shipping product",
  "SAVER-PICKUP": "Sendle Saver - Pickup, economy shipping product",
  "STANDARD-DROPOFF": "Sendle Preferred - Drop off, preferred shipping product",
  "STANDARD-PICKUP": "Sendle Preferred - Pickup, preferred shipping product",
  "THREE-DAY-PICKUP": "Sendle 3-Day Guaranteed - Pickup, three-day service",
  "THREE-DAY-DROPOFF": "Sendle 3-Day Guaranteed - Drop off, three-day service",
  "TWO-DAY-PICKUP": "Sendle 2-Day Guaranteed - Pickup, two-day service",
  "TWO-DAY-DROPOFF": "Sendle 2-Day Guaranteed - Drop off, two-day service",
  "GROUND-ADVANTAGE-PICKUP":
    "Ground Advantage Plus - Pickup, affordable US delivery",
  "GROUND-ADVANTAGE-DROPOFF":
    "Ground Advantage Plus - Drop off, affordable US delivery",
  "PRIORITY-PICKUP": "Priority Mail Plus - Pickup, priority US delivery",
  "PRIORITY-DROPOFF": "Priority Mail Plus - Drop off, priority US delivery",
  "PRIORITY-EXPRESS-PICKUP":
    "Priority Mail Express Plus - Pickup, express US delivery",
  "PRIORITY-EXPRESS-DROPOFF":
    "Priority Mail Express Plus - Drop off, express US delivery",
};

export const sendleInternationalServices = {
  "STANDARD-PICKUP":
    "Sendle Preferred - Pickup, supports international shipments from USA and Australia",
  "STANDARD-DROPOFF":
    "Sendle Preferred - Drop off, supports international shipments from USA and Australia",
};
