export const fedexPackageTypes = {
  YOUR_PACKAGING: "Customer Packaging, FedEx Express® Services(150 KG/68 lbs)",
  //   YOUR_PACKAGING: "Customer Packaging, FedEx SmartPost® Services(70 KG/32 lbs)",
  FEDEX_ENVELOPE: "FedEx® Letter(1 KG/0.5 lbs)",
  FEDEX_BOX: "FedEx® Box(20 KG/9 lbs)",
  FEDEX_SMALL_BOX: "FedEx® Small Box(20 KG/9 lbs)",
  FEDEX_MEDIUM_BOX: "FedEx® Medium Box(20 KG/9 lbs)",
  FEDEX_LARGE_BOX: "FedEx® Large Box(20 KG/9 lbs)",
  FEDEX_EXTRA_LARGE_BOX: "FedEx® Extra Large Box(20 KG/9 lbs)",
  FEDEX_10KG_BOX: "FedEx® 10kg Box(22 KG/10 lbs)",
  FEDEX_25KG_BOX: "FedEx® 25kg Box(55 KG/25 lbs)",
  FEDEX_PAK: "FedEx® Pak(20 KG/9 lbs)",
  FEDEX_TUBE: "FedEx® Tube(20 KG/9 lbs)",
};
