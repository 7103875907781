import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Link,
  Typography,
  Drawer,
  List,
  ListItem,
  Box,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { Link as RouterLink } from "react-router-dom";
import reachship_nav_dark from "../assets/reachship_logo_transparent_dark.png";

const TopNavbar = ({ history }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  return (
    <AppBar
      style={{
        background: "#fff",
        fontFamily: "Montserrat,sans-serif",
      }}
      position="fixed"
      className="navbar"
      elevation={0}
    >
      <Toolbar>
        <Link component={RouterLink} to="/">
          <figure>
            <img
              src={reachship_nav_dark}
              alt="reachship_navbar_logo"
              style={{ height: "30px", width: "110px", paddingTop: "5px" }}
            />
            <figcaption>
              <img
                src={
                  "https://reachship.com/wp-content/uploads/2019/08/regular-ELEX-logo.png"
                }
                alt="elex-logo"
                className="reachship-small-logo-auth-elex-caption"
              />
            </figcaption>
          </figure>
        </Link>

        <Typography style={{ flex: 1 }}></Typography>

        <Box
          sx={{
            display: {
              xs: "block",
              sm: "block",
              md: "block",
              lg: "none",
              xl: "none",
            },
          }}
        >
          <MenuIcon
            style={{
              color: "black",
              cursor: "pointer",
              paddingTop: "25px",
            }}
            onClick={() => setDrawerOpen(true)}
          />
          <Drawer
            open={drawerOpen}
            anchor="left"
            onClose={() => setDrawerOpen(false)}
          >
            <List style={{ width: 250 }}>
              <ListItem>
                <Link
                  underline="hover"
                  className="appbar-links"
                  href="https://reachship.com"
                >
                  Home
                </Link>
              </ListItem>
              {/* <ListItem>
                <Link
                  underline="hover"
                  className="appbar-links"
                  href="https://reachship.com/category/blog/"
                >
                  Blog
                </Link>
              </ListItem> */}
              {/* <ListItem>
                <Link
                  underline="hover"
                  className="appbar-links"
                  href="https://reachship.com/knowledge-base/"
                >
                  Doc
                </Link>
              </ListItem> */}
              {/* <ListItem>
                <Link
                  underline="always"
                  className="appbar-links"
                  href="https://developer.reachship.com/"
                >
                  API Doc
                </Link>
              </ListItem> */}
              <ListItem>
                <Link
                  underline="hover"
                  className="appbar-links"
                  href="https://support.reachship.com/"
                >
                  Support
                </Link>
              </ListItem>
              <ListItem>
                <Link
                  className="appbar-links"
                  style={{ paddingRight: "0.5rem" }}
                  underline="hover"
                  href="https://ship.reachship.com/"
                >
                  Get Started
                </Link>
              </ListItem>
            </List>
          </Drawer>
        </Box>

        <Box
          sx={{
            display: {
              xs: "none",
              sm: "none",
              md: "none",
              lg: "block",
              xl: "block",
            },
          }}
        >
          &nbsp;&nbsp;&nbsp;&nbsp;
          <Link
            className="appbar-links"
            underline="hover"
            href="https://reachship.com"
          >
            Home
          </Link>
          {/* <Link
            className="appbar-links"
            underline="hover"
            href="https://reachship.com/category/blog/"
          >
            Blog
          </Link> */}
          {/* <Link
            className="appbar-links"
            underline="hover"
            href="https://reachship.com/knowledge-base/"
          >
            Doc
          </Link> */}
          {/* &nbsp;&nbsp;&nbsp;&nbsp; */}
          {/* <Link
            className="appbar-links"
            underline="always"
            style={{ textDecoration: "underline" }}
            href="https://developer.reachship.com/"
          >
            <span>API Doc</span>
          </Link> */}
          &nbsp;&nbsp;&nbsp;&nbsp;
          <Link
            className="appbar-links"
            underline="hover"
            href="https://support.reachship.com/"
          >
            Support
          </Link>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <Link
            className="appbar-links"
            style={{ paddingRight: "0.5rem", fontWeight: 800 }}
            underline="hover"
            href="https://ship.reachship.com/"
          >
            Get Started
          </Link>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default TopNavbar;
